import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Speaking = () => {
  const [upcomingConferences, setUpcomingConferences] = useState([]);
  const [pastConferences, setPastConferences] = useState([]);

  useEffect(() => {
    setUpcomingConferences([]);

    setPastConferences([
      {
        conference: "JS World Conference",
        link: "https://frontenddeveloperlove.com/",
        talkTitle: "Design from the realm of open-source",
        date: "22-02-2021",
        country: "Amsterdam, The Netherlands",
      },
      {
        conference: "Chaos Engineering Conference",
        link: "https://www.conf42.com/ce2021",
        talkTitle: "Technical Principles of Developer Experience",
        date: "25-02-2021",
        country: "London, United Kingdom",
      },
      {
        conference: "SINFO Conference",
        link: "https://sinfo.org/",
        talkTitle: "Technical Principles of Developer Experience",
        date: "01-03-2021",
        country: "Lisbon, Portugal",
      },
      {
        conference: "FOSS ASIA",
        link: "https://eventyay.com/e/fa96ae2c/speakers",
        talkTitle: "Technical Principles of Developer Experience",
        date: "13-03-2021",
        country: "Singapore, Asia",
      },
      {
        conference: "NDC London 2021",
        link: "https://ndc-london.com/speakers/shodipo-ayomide",
        talkTitle: "Technical Principles of Developer Experience",
        date: "29-01-2021",
        country: "London, United Kingdom",
      },
      {
        conference: "Auth0 Avocado Labs",
        link: "https://avocadolabs.dev/events/technical-principles-dx/",
        talkTitle: "Technical Principles of Developer Experience",
        date: "11-02-2021",
        country: "Bellevue, WA, United States",
      },
      {
        conference: "MautiCon",
        link: "https://2020.opencoresummit.com/#speakers",
        talkTitle: "The Ideal Community Model: Lessons Learned",
        date: "17-11-2020",
        country: "San Francisco, United States",
      },
      {
        conference: "Open Core Summit",
        link: "https://2020.opencoresummit.com/#speakers",
        talkTitle: "Design from the Realm of Open Source",
        date: "19-11-2020",
        country: "San Francisco, United States",
      },
      {
        conference: "GitHub Nova Conference",
        link: "https://github.com/",
        talkTitle: "Technical Principles Of Developer Experience",
        date: "01-12-2020",
        country: "San Francisco, United States",
      },
      {
        conference: "FrontEnd Connect",
        link: "https://frontend-con.io/speakers-2020/",
        talkTitle: "Progressive Imaging & Handling: React + WebPack",
        date: "08-12-2020",
        country: "Warszawa, Polska",
      },
      {
        conference: "Frontend Love Conference",
        link: "https://www.frontenddeveloperlove.com/",
        talkTitle: "Design from the dimension of Open Source",
        date: "01-02-2021",
        country: "Amsterdam, Noord-Holland",
      },
      {
        conference: "LeadDev Conference",
        link: "https://leaddev.com/",
        talkTitle: "The Ideal Community Model: Lessons Learned",
        date: "TBA",
        country: "San Francisco, United States",
      },
      {
        conference: "All Day DevOps",
        link: "https://www.alldaydevops.com/addo-speakers/shodipo-ayomide",
        talkTitle: "Securing Serverless Applications using Azure",
        date: "12-11-2020",
        country: "McLean, Virginia",
      },
      {
        conference: "Vue Toronto",
        link: "https://vuetoronto.com/speakers",
        talkTitle: "Fast and Furious with VueJS & WebPack",
        date: "04-11-2020",
        country: "Toronto, Ontario",
      },
      {
        conference: "Codemotion Amsterdam 1",
        link: "https://events.codemotion.com/conferences/online/2020/codemotion-online-tech-conference/speakers",
        talkTitle: "Getting up to Speed With Deno",
        date: "20-10-2020",
        country: "Amsterdam, The Netherlands",
      },
      {
        conference: "GitHub Africa",
        link: "https://www.meetup.com/GitHub-Africa/events/273641234/",
        talkTitle: "Automating Workflow Processes Using GitHub Actions",
        date: "20-10-2020",
        country: "Africa",
      },
      {
        conference: "Connect.Tech Conference",
        link: "https://connect.tech/session?id=1829",
        talkTitle: "Getting up to Speed With Deno",
        date: "14-10-2020",
        country: "Atlanta, United States",
      },
      {
        conference: "APIDAYS Hong Kong",
        link: "https://www.apidays.co/hongkong/",
        talkTitle: "Securing Serverless Applications using Azure",
        date: "08-10-2020",
        country: "Hong Kong, China",
      },
      {
        conference: "Shift Conference",
        link: "https://remote.shiftconf.co/",
        talkTitle: "Design From the Realm of Open Source",
        date: "24-09-2020",
        country: "Split, Croatia",
      },
      {
        conference: "Open Up Summit",
        link: "https://www.openup.global/?lang=en",
        talkTitle: "Design From the Realm of Open Source",
        date: "12-09-2020",
        country: "Taiwan, China",
      },
      {
        conference: "Open Source Initiative (OSI) - State of the Source",
        link: "https://eventyay.com/e/8fa7fd14/speakers",
        talkTitle: "Design From the Dimension of Open Source",
        date: "09-09-2020",
        country: "San Francisco, United States",
      },
      {
        conference: "React Atlanta",
        link: "https://www.meetup.com/React-ATL/events/272675044/",
        talkTitle: "Progressive Imaging & Handling in WebPack",
        date: "08-09-2020",
        country: "Atlanta, United States",
      },
      {
        conference: "JavaScript and Friends",
        link: "https://www.javascriptandfriends.com/communityevents",
        talkTitle: "Styling Your ReactJS Components For Reuse",
        date: "14-07-2020",
        country: "Columbus, Ohio",
      },
      {
        conference: "Favoured Network",
        link: "https://www.linkedin.com/posts/opeyemi-adeniran-66200b150_starting-start-starting-activity-6693932802901086208-ZHR0",
        talkTitle: "Engineering the Future (keynote)",
        date: "02-08-2020",
        country: "Lagos, Nigeria",
      },
      {
        conference: "DevRel.Events",
        link: "https://devrel.events/youtube-dev-blogs-tech-influencers-meetup/",
        talkTitle: "Principles Guiding Technical Articles",
        date: "22-07-2020",
        country: "Stockholm, Sweden",
      },
      {
        conference: "React Native Nigeria Conference",
        link: "https://www.eventbrite.com/e/react-native-to-infinity-and-beyond-tickets-108692022766#",
        talkTitle: "After create-react-native-app What's Next?",
        date: "27-06-2020",
        country: "Lagos, Nigeria",
      },
      {
        conference: "Juneteenth Conf",
        link: "https://www.juneteenthconf.com/speakers.html",
        talkTitle: "The Ideal Community Model: Lessons Learned",
        date: "19-06-2020",
        country: "Minneapolis, MN",
      },
      {
        conference: "The European R Users Conference",
        link: "https://2020.erum.io/program/contributed-sessions/",
        talkTitle: "Fake News: AI on the battle ground",
        date: "17-06-2020",
        country: "Milano, Italy",
      },
      {
        conference: "In Streaming Color",
        link: "https://www.instreamingcolor.com/",
        talkTitle: "The Ideal Community Model: Lessons Learned",
        date: "24-05-2020",
        country: "Atlanta, US",
      },
      {
        conference: "JavaScript Monthly",
        link: "https://www.meetup.com/js-monthly/events/270355422/",
        talkTitle: "Lazy Loading Images and Bundle Analyzing with WebPack",
        date: "28-05-2020",
        country: "London, UK",
      },
      {
        conference: "Angular Kenya",
        link: "https://twitter.com/angular_kenya/status/1264617409660542977?s=20",
        talkTitle: "Design from the Realm of open-source",
        date: "28-05-2020",
        country: "Nairobi, Kenya",
      },
      {
        conference: "JavaScript and Friends",
        link: "https://www.javascriptandfriends.com/communityevents/",
        talkTitle: "Lazy Loading Images and Bundle Analyzing with WebPack",
        date: "27-05-2020",
        country: "Columbus, Ohio",
      },
      {
        conference: "FutureSync Conference",
        link: "https://2020.futuresync.co.uk/speakers/shodipo-ayomide",
        talkTitle: "Design from the Realm of open-source",
        date: "16-04-2020",
        country: "Plymoth, United Kingdom",
      },
      {
        conference: "FrontStack Conference",
        link: "https://medium.com/frontstackio/frontstack-event-recap-media-on-the-web-a3b03ecc8894",
        talkTitle: "Lazy Loading Images and Bundle Analyzing with WebPack",
        date: "25-01-2020",
        country: "Lagos, Nigeria",
      },
      {
        conference: "Full Stack Conference",
        link: "#",
        talkTitle: "Getting Started with GraphQL",
        date: "04-05-2019",
        country: "Lagos, Nigeria",
      },
      {
        conference: "NodeJS Conference",
        link: "#",
        talkTitle: "The Future of Work For Software Engineers",
        date: "16-04-2019",
        country: "Lagos, Nigeria",
      },
      {
        conference: "DevFest Lagos 2019",
        link: "#",
        talkTitle: "Panel: Future of Work",
        date: "28-05-2019",
        country: "Lagos, Nigeria",
      },
      {
        conference: "DevFest Ikorodu",
        link: "#",
        talkTitle: "Design from the Realm of open-source",
        date: "16-11-2019",
        country: "Lagos, Nigeria",
      },
      {
        conference: "DevFest Lagos 2018",
        link: "#",
        talkTitle: "The Future of Work",
        date: "02-11-2018",
        country: "Lagos, Nigeria",
      },
    ]);
  }, []);

  const tableVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const rowVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
      },
    },
  };

  const ConfTable = ({ conferences, title }) => (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-4">{title}</h2>
      <motion.div
        className="overflow-x-auto"
        variants={tableVariants}
        initial="hidden"
        animate="visible"
      >
        <table className="w-full text-sm">
          <thead className="text-xs uppercase bg-gray-200">
            <tr>
              <th className="px-4 py-3 text-left w-1/4">Conference</th>
              <th className="px-4 py-3 text-left w-1/3">Talk Title</th>
              <th className="px-4 py-3 text-right w-1/6">Date</th>
              <th className="px-4 py-3 text-left w-1/4">Country</th>
            </tr>
          </thead>
          <tbody>
            {conferences.map((conf, index) => (
              <motion.tr
                key={index}
                className="border-b hover:bg-gray-50"
                variants={rowVariants}
              >
                <td className="px-4 py-4 font-medium w-1/4">
                  <a
                    href={conf.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline break-words"
                  >
                    {conf.conference}
                  </a>
                </td>
                <td className="px-4 py-4 w-1/3">{conf.talkTitle}</td>
                <td className="px-4 py-4 text-right whitespace-nowrap w-1/6">
                  {conf.date}
                </td>
                <td className="px-4 py-4 w-1/4">{conf.country}</td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </motion.div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#f6f2d9] p-4 sm:p-6 relative font-mono">
      <div className="max-w-7xl mx-auto pt-16">
        <motion.h1
          className="text-2xl sm:text-3xl font-bold mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Speaking Engagements
        </motion.h1>

        <motion.div
          className="mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <p className="text-sm">
            Email:{" "}
            <a href="mailto:shodipovi@gmail.com" className="hover:underline">
              shodipovi@gmail.com
            </a>
          </p>
        </motion.div>

        <div className="overflow-hidden">
          <ConfTable
            conferences={upcomingConferences}
            title="Upcoming Conferences"
          />
          <ConfTable
            conferences={pastConferences}
            title="Past Conferences"
          />
        </div>
      </div>
    </div>
  );
};

export default Speaking;
