import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./components/pages/Home";
import Speaking from "./components/pages/Speaking";
import Contact from "./components/pages/Contact";
import { Menu, MenuDetails } from "./components/layout/Menu";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!menuOpen && isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [menuOpen, isTransitioning]);

  const toggleMenu = () => {
    if (menuOpen) {
      setIsTransitioning(true);
    }
    setMenuOpen(!menuOpen);
  };

  const useMetaData = () => {
    const baseTitle = "Home | Shodipo Ayomide";
    const baseDesc = "Head of Developer Relations at Nuklai Protocol.";

    switch (location.pathname) {
      case "/":
        return { title: baseTitle, description: baseDesc };
      case "/speaking":
        return {
          title: `Speaking | ${baseTitle}`,
          description:
            "Shodipo Ayomide's speaking engagements, conferences, and talks.",
        };
      case "/contact":
        return {
          title: `Get in touch | ${baseTitle}`,
          description: "Get in touch.",
        };
      default:
        return { title: baseTitle, description: baseDesc };
    }
  };

  const { title, description } = useMetaData();

  return (
    <div className="min-h-screen bg-[#f6f2d9] py-8 relative">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <nav className="absolute top-6 right-6 z-30">
        <div className="hidden md:flex space-x-6">
          <MenuDetails setMenuOpen={setMenuOpen} />
        </div>

        <div className="md:hidden">
          <Menu isOpen={menuOpen} toggle={toggleMenu} />

          <div
            className={`fixed inset-0 bg-[#f6f2d9] z-20 transition-opacity duration-300 ease-in-out
            ${
              menuOpen || isTransitioning
                ? "opacity-100"
                : "opacity-0 pointer-events-none"
            }`}
          >
            <div className="absolute top-6 right-6">
              <Menu isOpen={menuOpen} toggle={toggleMenu} />
            </div>
            <div className="flex flex-col items-center justify-center h-full space-y-8">
              <MenuDetails setMenuOpen={setMenuOpen} />
            </div>
          </div>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/speaking" element={<Speaking />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
