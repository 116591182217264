import React from "react";

const Home = () => {
  return (
    <div className="min-h-screen bg-[#f6f2d9] p-6 flex justify-center items-center font-mono">
      <div className="mt-10 sm:mt-0">
        <h1 className="text-3xl font-bold mb-6">Shodipo Ayomide</h1>
        <ul className="space-y-3 text-sm">
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              Head of Developer Relations at{" "}
              <a
                href="https://nuklai.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                <b>Nuklai Protocol</b>
              </a>
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              Software Architect, Full-Stack Engineer, Smart Contracts, LLMs
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <b>Prev:</b> Led Developer Relations at{" "}
              <a
                href="https://polygon.technology/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                Polygon Technology
              </a>
              ,{" "}
              <a
                href="https://www.concordium.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                Concordium Labs
              </a>
              ,{" "}
              <a
                href="https://www.intmax.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                Intmax Protocol
              </a>
              ,{" "}
              <a
                href="https://chroniclelabs.org/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                Chronicle Labs
              </a>
              ...
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>Technical Advisor</span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              Investor in startups<b>(pre-seed/seed)</b>. Some examples:
              <ul className="ml-4 mt-1 space-y-0.5">
                {[
                  "Sunrise Layer",
                  "Fig (exited)",
                  "Get Convoy (YC W22)",
                  "Afropolitan",
                  "Kingdom (exited)",
                  "Try Grip",
                  "Bitpowr (500 Global W22)",
                  "Spire",
                  "InfluxAI",
                  "Trail AI",
                  "...",
                ].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 mt-1">◦</span>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <b>Member:</b> Forbes Technology Council
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <a
                href="mailto:shodipovi@gmail.com"
                className="text-black hover:underline hover:cursor-pointer"
              >
                Email - shodipovi@gmail.com
              </a>
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <a
                href="http://github.com/developerayo"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                GitHub
              </a>
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <a
                href="https://x.com/developerayo"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                X(Twitter)
              </a>
            </span>
          </li>
          <li className="flex items-start">
            <span className="mr-2 mt-1">•</span>
            <span>
              <a
                href="https://www.linkedin.com/in/shodipo-ayomide/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline hover:cursor-pointer"
              >
                LinkedIn
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
