import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { motion } from 'framer-motion';

const Contact = () => {
  const [state, handleSubmit] = useForm("xanynyjj");

  const inputClass = "w-full bg-transparent border-b border-gray-700 focus:border-black focus:outline-none py-2 transition-colors duration-300 text-gray-800 placeholder-gray-500";

  if (state.succeeded) {
    return (
      <motion.div 
        className="min-h-screen bg-[#f6f2d9] p-4 sm:p-6 relative font-mono flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl font-bold text-gray-800 text-center"
        >
          Thanks for your message! I'll get back to you soon.
        </motion.p>
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="min-h-screen bg-[#f6f2d9] p-4 sm:p-6 relative font-mono"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-2xl mx-auto pt-16">
        <motion.h1
          className="text-3xl font-bold mb-8 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get In Touch
        </motion.h1>

        <motion.form
          onSubmit={handleSubmit}
          className="space-y-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <div>
            <input
              type="text"
              id="name"
              name="name"
              required
              placeholder="Your name"
              className={inputClass}
            />
            <ValidationError prefix="Name" field="name" errors={state.errors} className="text-red-600 text-sm mt-1" />
          </div>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="say@nukl.ai"
              className={inputClass}
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} className="text-red-600 text-sm mt-1" />
          </div>
          <div>
            <select
              id="reason"
              name="reason"
              required
              className={`${inputClass} appearance-none`}
            >
              <option value="">Select a reason for contact</option>
              <option value="speaking">Speaking Engagement</option>
              <option value="investment">Investments (Pre-Seed & Seed Stage) Only</option>
              <option value="advice">Technical Advisory</option>
              <option value="collaboration">Collaboration Proposal</option>
              <option value="other">Other</option>
            </select>
            <ValidationError prefix="Reason" field="reason" errors={state.errors} className="text-red-600 text-sm mt-1" />
          </div>
          <div>
            <textarea
              id="message"
              name="message"
              rows="4"
              placeholder="Any Extra information?"
              className={`${inputClass} resize-none`}
            ></textarea>
            <ValidationError prefix="Message" field="message" errors={state.errors} className="text-red-600 text-sm mt-1" />
          </div>
          <motion.button
            type="submit"
            className="px-6 py-2 bg-gray-800 text-white rounded-md hover:bg-black transition-colors duration-300 relative"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={state.submitting}
          >
            {state.submitting ? (
              <svg className="animate-spin h-5 w-5 mx-auto" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              "Send Message"
            )}
          </motion.button>
        </motion.form>

        <motion.div
          className="mt-8 text-sm text-gray-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <p>You can also reach me at:</p>
          <ul className="mt-2 space-y-1">
            <li>
              <a href="mailto:shodipovi@gmail.com" className="hover:underline text-gray-800">shodipovi@gmail.com</a>
            </li>
            <li>
              <a href="https://twitter.com/developerayo" target="_blank" rel="noopener noreferrer" className="hover:underline text-gray-800">Twitter: @developerayo</a>
            </li>
            <li>
              <a href="https://github.com/developerayo" target="_blank" rel="noopener noreferrer" className="hover:underline text-gray-800">GitHub: developerayo</a>
            </li>
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;