import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Menu = ({ isOpen, toggle }) => (
  <button
    className="w-6 h-6 flex flex-col justify-center items-end overflow-hidden"
    onClick={toggle}
  >
    <span
      className={`bg-black transition-all duration-300 ease-in-out origin-center
      ${
        isOpen
          ? "w-[120%] h-0.5 absolute rotate-45 translate-x-[-10%]"
          : "w-5 h-0.5 mb-1.5"
      }`}
    />
    <span
      className={`w-4 h-0.5 bg-black transition-all duration-300 ${
        isOpen ? "opacity-0" : ""
      }`}
    />
    <span
      className={`bg-black transition-all duration-300 ease-in-out origin-center
      ${
        isOpen
          ? "w-[120%] h-0.5 absolute -rotate-45 translate-x-[-10%]"
          : "w-3 h-0.5 mt-1.5"
      }`}
    />
  </button>
);

export const MenuDetails = ({ setMenuOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { path: "/", label: "Home" },
    { path: "/speaking", label: "Speaking" },
    {
      path: "https://blog.shodipoayomide.com",
      label: "Writing",
      external: true,
    },
    { path: "/contact", label: "Get In Touch" },
  ];

  const handleMenuItemClick = (item) => {
    setMenuOpen(false);
    if (!item.external) {
      setTimeout(() => {
        navigate(item.path);
      }, 300);
    } else {
      setTimeout(() => {
        window.open(item.path, "_blank");
      }, 300);
    }
  };

  const dispMenuItem = (item) => {
    return (
      <button
        key={item.path}
        onClick={() => handleMenuItemClick(item)}
        className={`text-lg hover:underline ${
          location.pathname === item.path ? "underline" : ""
        }`}
      >
        {item.label}
      </button>
    );
  };

  return (
    <>
      {menuItems.map((item) => {
        if (location.pathname === "/" && item.path === "/") {
          return null;
        }
        return dispMenuItem(item);
      })}
    </>
  );
};
